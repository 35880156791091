// Create a Providers component to wrap your application with all the components requiring 'use client', such as next-nprogress-bar or your different contexts...
"use client";

import { AppProgressBar } from "next-nprogress-bar";

const ProgressBar = () => {
	return (
		<AppProgressBar
			height="4px"
			color="#0ea5e9"
			options={{ showSpinner: false }}
			shallowRouting
		/>
	);
};

export default ProgressBar;
